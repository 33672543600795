import React, {Component} from 'react';
import {withStyles} from "@material-ui/core";
import Modal from "../../../common/modal/modal";
import Config from "../../../../config";
import GridContainer from "../../../grid-container/grid-container";
import {Column} from "devextreme-react/data-grid";
import UserImage from "../../../common/user/user-image";
import _ from "lodash";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import * as generalActions from "../../../../redux/general/general_actions";
import UserName from "../../../common/user/user-name";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import {TextField} from "../../../common/form-material";
import ButtonCustom from "../../../common/button/button-custom";
import Api from "../../../../services/api";

const styles = () => ({
    grid247: {
        "& .grid-container": {
            "& > .paging": {
                position: "absolute",
            }
        }

    }
})

class W75F8001 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRowKeys: [],
        };
        this.preSelectedRowKeys = React.createRef();
    }

    getCurSelectedRowKeys = (data = [], dataSelected = []) => _.reduce(data, (acc, item) => {
        if (_.includes(dataSelected, _.get(item, "EmployeeID", ""))) {
            acc = [...acc, item.EmployeeID]
        }
        return acc;
    }, []);

    onChangePage = (page) => {
        const {selectedRowKeys} = this.state;
        this.tmpSelectedRowKeys = selectedRowKeys;
        this.props.onChangePage({skip: page * this.props.limit})
    };

    onChangePerPage = (perPage) => {
        const {selectedRowKeys} = this.state;
        this.tmpSelectedRowKeys = selectedRowKeys;
        this.props.onChangePage({skip: 0, limit: perPage})
    };

    onAgree = () => {
        const {onClose, voucherSelected} = this.props;
        const {selectedRowKeys} = this.state;
        const jsonDataParam = JSON.stringify(_.reduce(selectedRowKeys, (accUser, UserID) => {
            accUser = [...accUser, ..._.map(voucherSelected, item => ({
                ..._.pick(item, ['ApprovalFlowID', "ApprovalLevel", "TransID", "ObjectID"]),
                UserID,
                VoucherID: _.get(item, "TransID", "")
            }))];
            return accUser;
        }, []));
        const params = {
            delete: jsonDataParam,
            data: jsonDataParam
        }
        Config.popup.show("YES_NO", Config.lang("Ban_co_muon_thay_the_nguoi_duyet"), () => {
            (async () => {
                const res = await Api.put("/w75f8000/choose-approver", params);
                const resData = _.get(res, "data", []);
                const resDataErrMessage = _.get(resData, "Message", "");
                const resStatus = _.get(resData, "Status", 1);
                const resErrMessage = _.get(res, "message", "");
                if (resErrMessage || resDataErrMessage || resStatus) {
                    Config.popup.show("ERROR", resErrMessage);
                    return false;
                }
                Config.notify.show("success", Config.lang("Thay_the_nguoi_duyet_thanh_cong"), 2000);
                onClose("showW75F8001", true);
            })();
        });
    }

    onSelectionChanged = async ({selectedRowKeys}) => {
        const {getCboEmployees} = this.props;
        let allSelectedRowKeys = _.uniq([...(this.preSelectedRowKeys.current || []), ...selectedRowKeys]);
        const currentRowSelected = this.getCurSelectedRowKeys(getCboEmployees.rows, allSelectedRowKeys);
        const deselectedRowKeys = _.filter(currentRowSelected, rowKey => !_.includes(selectedRowKeys, rowKey));
        await (() => this.preSelectedRowKeys.current = _.filter(allSelectedRowKeys, rowKey => !_.includes(deselectedRowKeys, rowKey)))();
        this.setState({selectedRowKeys});
    }

    renderAvatar = (e) => {
        const {data} = e.row;
        return (<div style={{display: "flex", justifyContent: "center"}}>
            <UserImage width={38} height={38} data={data}/>
        </div>);
    };

    renderInfo = (e) => {
        const {data} = e.row;
        return (
            <div style={{display: "flex", flexDirection: "column"}}>
                <UserName allowHover={false} data={data}/>
                <div>{_.get(data, "EmployeeID", "")}</div>
                <div>{_.get(data, "DutyName", "")}</div>
            </div>
        )
    }

    render() {
        const {selectedRowKeys} = this.state;
        const {getCboEmployees, open, onClose, onSearchEmployee, loading, skip, limit} = this.props;
        const countNumberAllSelectedRowKeys = _.size(_.uniq([...(this.preSelectedRowKeys.current ?? []), ...selectedRowKeys]))
        const hasAEmployeeChecked = countNumberAllSelectedRowKeys > 0;
        return (
            <Modal open={open}
                   maxWidth={"sm"}
                   fullWidth={true}
                   onClose={() => onClose('showW75F8001')}
                   title={`${Config.lang("Chon_nguoi_duyet_thay_the")} ${!!countNumberAllSelectedRowKeys ? countNumberAllSelectedRowKeys : ""}`}
            >
                <Modal.Content>
                    <TextField
                        placeholder={Config.lang('Thong_tin_nguoi_duyet')}
                        variant={"outlined"}
                        InputLabelProps={{
                            shrink: true
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon/>
                                </InputAdornment>
                            )
                        }}
                        onChange={e => onSearchEmployee(_.get(e, "target.value", ""))}
                        fullWidth
                    />
                    <GridContainer
                        height={650}
                        style={{border: 'none'}}
                        keyExpr={'EmployeeID'}
                        dataSource={getCboEmployees.rows}
                        showRowLines={false}
                        showBorders={false}
                        showColumnLines={false}
                        showColumnHeaders={false}
                        noDataText={Config.lang("No_data")}
                        loading={loading}
                        hoverStateEnabled={true}
                        allowColumnResizing={true}
                        totalItems={getCboEmployees.total}
                        itemPerPage={limit}
                        skipPerPage={skip}
                        typePaging={"remote"}
                        onChangePage={this.onChangePage}
                        onChangePerPage={this.onChangePerPage}
                        selection={{
                            allowSelectAll: true,
                            mode: "multiple",
                            selectAllMode: "allPages",
                            showCheckBoxesMode: "always"
                        }}
                        selectedRowKey={this.getCurSelectedRowKeys(getCboEmployees.rows, this.preSelectedRowKeys.current)}
                        pagerFullScreen={false}
                        onSelectionChanged={this.onSelectionChanged}
                    >
                        <Column width={85} alignment={"center"} cellRender={this.renderAvatar}/>
                        <Column alignment={"left"} cellRender={this.renderInfo}/>
                    </GridContainer>
                </Modal.Content>
                <Modal.Actions>
                    <ButtonCustom
                        disabled={!hasAEmployeeChecked}
                        name={Config.lang('Dong_y')}
                        size={"large"}
                        color={"primary"}
                        variant={"contained"}
                        onClick={this.onAgree}/>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default compose(connect((state) => ({
    getCboEmployees: state.general.getCboEmployees,
}), (dispatch) => ({
    generalActions: bindActionCreators(generalActions, dispatch),
})), withStyles(styles, {withTheme: true}))(W75F8001)