import React, {Component} from 'react';
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Config from "../../../../config";
import ButtonGeneral from "../../../common/button/button-general";
import {Tab, Tabs, Icon, withStyles, IconButton} from "@material-ui/core";
import {Row, Col, FormGroup} from "react-bootstrap";
import {bindActionCreators, compose} from "redux";
import GridContainer from "../../../grid-container/grid-container";
import {Column} from "devextreme-react/data-grid";
import Api from "../../../../services/api";
import _ from "lodash";
import * as generalActions from "../../../../redux/general/general_actions";
import Filter from "../../../filter/filter";
import {Combo, TextField} from "../../../common/form-material";
import DateBoxPicker from "../../../common/form-material/date-box";
import {connect} from "react-redux";
import UserImage from "../../../common/user/user-image";
import W75F8002 from "../W75F8002/W75F8002";
import W75F8001 from "../W75F8001/W75F8001";
import Modal from "../../../common/modal/modal";

const styles = () => ({
    contentContainer: {
        borderColor: "rgb(0 0 0 / 12%)",
        borderStyle: "solid",
        borderLeftWidth: 0.5,
        borderRightWidth: 0.5,
        borderBottomWidth: 0,
        borderTopWidth: 0
    },
    tabs: {
        "& .MuiButtonBase-root": {
            zIndex: 1,
            "&.Mui-selected > .MuiTab-wrapper": {
                color: "#FFF"
            },
            "&:after": {
                height: 0.5,
                backgroundColor: "0.5px solid rgb(0 0 0 / 12%)"
            }
        },
        "& .MuiTabs-indicator": {
            width: "100%",
            height: "41.5px !important",
            marginRight: 0
        }
    },
    tabGridRight: {
        width: "90%",
        borderLeft: "0.5px solid rgb(0 0 0 / 12%)",
    },
    approverCol: {
        display: "flex",
        flexDirection: "column",
        maxHeight: 105,
        overflowX: "scroll"
    },
})

class W75F8000 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            module: null,
            titleModal: "",
            FormID: '',
            keyProps: "TransID",
            GroupID: 0,
            StatusID: 0,
            transIDSelected: "",
            DateFrom: "",
            DateTo: "",
            ObjectName: "",
            dataGrid: {rows: [], total: 0},
            selectedRowsData: [],
            selectedRowKeys: [],
            dataCboEmployees: {rows: [], total: 0},
            dataCboTransaction: [],
            isEmployeeLoading: false,
            isCboTransactionLoading: false,
            isGridLoading: false,
            showW75F8001: false,
            showW75F8002: false,
            showPopupByFormID: false,
            totalDataOT1stStatusTab: 0,
        };
        this.params = {
            FormID: "W75F8000",
            Language: Config.language || '84'
        };
        this.filter = {
            Employee: null,
            TransactionID: "ALL",
            skip: 0,
            limit: 10,
        };
        this.filterEmployees = [
            {
                ...this.params,
                search: "",
                timer: null,
                skip: 0,
                limit: 50
            },
            {
                FormID: "W75F8001",
                Language: Config.language || '84',
                search: "",
                skip: 0,
                limit: 10
            }
        ];
        this.preSelectedRowKeys = React.createRef();
        this.preDataRowsSelected = React.createRef();
        this.onSearchEmployeeGridPopup = _.debounce(this.onSearchEmployeeGrid, 1000);
    };

    resetParamOnChangingTab = () => {
        this.filter = {
            Employee: null,
            TransactionID: "ALL",
            skip: 0,
            limit: 10,
        };
        this.filterEmployees[0] = {
            ...this.params,
            search: "",
            timer: null,
            skip: 0,
            limit: 50
        };
    }

    setTitleModalByFormID = (screenName) => {
        let title = "";
        switch (screenName) {
            case "W09F2011":
                title = Config.lang("Dang_ky_nghi_viec");
                break;
            case "W75F2000":
                title = Config.lang("Lap_nghi_phep")
                break;
            case "W09F2060":
                title = Config.lang("Lap_de_xuat_khen_thuong")
                break;
            case "W09F2070":
                title = Config.lang("Lap_de_xuat_ky_luat")
                break;
            case "W09F2080":
                title = Config.lang("Lap_de_xuat_dieu_chuyen_lao_dong")
                break;
            case "W09F2021":
                title = Config.lang("Cap_nhat_sau_thai_san")
                break;
            case "W29F2012":
                title = Config.lang("Dang_ky_tang_ca")
                break;
            case "W77F1013":
                title = Config.lang("")
                break;
            case "W75F2010":
                title = Config.lang("Lap_de_xuat_dieu_chinh_thu_nhap")
                break;
            case "W09F2150":
                title = Config.lang("Lap_de_xuat_thay_doi_chuc_danh")
                break;
            case "W09F2032":
                title = Config.lang("Dieu_chinh_luong")
                break;
            case "W25F2002":
                title = Config.lang("")
                break;
            default:
                break
        }
        return title
    }

    onDbCellClick = async (e) => {
        const formID = _.get(e, "data.FormID", "");
        const TransID = _.get(e, "data.TransID", "");
        let module = {default: null};
        let keyProps = "ProTransID";
        if (_.isEmpty(formID)) return false;
        switch (formID) {
            case "W09F2011":
                keyProps = "LeaveTransID";
                module = await import('../../../W0X/W09/W09F2010/W09F2010Popup');
                break;
            case "W09F2060":
                module = await import('../../../W0X/W09/W09F2060/W09F2060Popup');
                break;
            case "W09F2150":
                module = await import('../../../W0X/W09/W09F2150/W09F2150Popup');
                break;
            case "W09F2070":
                module = await import('../../../W0X/W09/W09F2070/W09F2070Popup');
                break;
            case "W09F2080":
                module = await import('../../../W0X/W09/W09F2080/W09F2080Popup');
                break;
            case "W09F2032":
                module = await import('../../../W0X/W09/W09F2030/W09F2032Popup');
                break;
            case "W09F2021":
                keyProps = "MaternityTransID";
                module = await import("../../../W0X/W09/W09F2021/W09F2021");
                break;
            case "W75F2000":
                keyProps = "TransID";
                module = await import('../../../W7X/W75/W75F2000/W75F2000Popup');
                break;
            case "W77F1013":
                keyProps = "TransID";
                module = await import('../../W77/W77F1013/W77F1013');
                break;
            case "W75F2010":
                keyProps = "VoucherID";
                module = await import("../W75F2010/W75F2010Popup");
                break;
            case "W29F2012":
                keyProps = "TransID";
                module = await import('../../../W2X/W29/W29F2012/W29F2012');
                break;
            case "W25F2002":
                keyProps = "TransID";
                module = await import('../../../W2X/W25/W25F2002/W25F2002');
                break;
            case "W29F2045":
                keyProps = "TransID";
                module = await import('../../../W2X/W29/W29F2045/W29F2045');
                break;
            default:
                break;
        }
        this.setState({
            titleModal: this.setTitleModalByFormID(formID),
            showPopupByFormID: true,
            module: module.default,
            FormID: formID ?? '',
            TransID,
            keyProps
        })
    }

    getCurrentRowsSelected = (type, data = [], dataSelected = []) =>
        _.size(dataSelected) > 0 ?
            _.reduce(data, (acc, item) => {
                if (_.includes(dataSelected, _.get(item, "TransID", ""))) {
                    acc = [...acc, type === "key" ? item.TransID : item]
                }
                return acc;
            }, []) : [];

    loadGrid = async () => {
        const {GroupID, StatusID, DateFrom, DateTo, ObjectName} = this.state;
        const {TransactionID, Employee, skip, limit} = this.filter;
        let statusIDOfTab = StatusID === 2 ? 4 : StatusID;
        const params = {
            ...this.params,
            GroupID,
            Object: ObjectName,
            StatusID: statusIDOfTab,
            DateFrom,
            DateTo,
            TransactionID,
            CreateUserID: _.get(Employee, "EmployeeID", ""),
            skip,
            limit
        };

        this.setState({isGridLoading: true});
        const res = await Api.put("/w75f8000/load-form", params);
        this.setState({isGridLoading: false});
        const resData = _.get(res, "data", []);
        const resDataErrMessage = _.get(resData, "Message", "");
        const resErrMessage = _.get(res, "message", "");
        if (resErrMessage || resDataErrMessage) {
            Config.popup.show("ERROR", resErrMessage || resDataErrMessage);
            return false;
        }
        const resDataRows = _.get(resData, "rows", resData) || [];
        const resDataTotal = _.get(resData, "total", resData.length);
        const resDataRowsWithIsUsed = _.chain(resDataRows).filter('IsUsed');

        const selectedRowKeysCurrent = _.uniq([
            ...(this.preSelectedRowKeys.current ?? []),
            ...resDataRowsWithIsUsed.map('TransID').value()
        ]);

        if (StatusID === 0) this.setState({totalDataOT1stStatusTab: resDataTotal});
        const rows = _.map(resDataRows, item => ({
            ...item,
            ApproverName: {dataApprover: _.get(item, "ApproverName", "").split(","), limit: 3}
        }));
        this.setState({
            dataGrid: {
                rows,
                total: resDataTotal
            },
            selectedRowsData: this.getCurrentRowsSelected("data", rows, selectedRowKeysCurrent),
            selectedRowKeys: this.getCurrentRowsSelected("key", rows, selectedRowKeysCurrent),
        });
    }

    loadCboEmployees = (isReset = false, type = 0) => {
        const {dataCboEmployees} = this.state;
        const params = {...this.filterEmployees[type], Type: "EmployeeID"};
        this.setState({isEmployeeLoading: true});
        this.props.generalActions.getCboEmployees(params, (err, data) => {
            this.setState({isEmployeeLoading: false});
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            }
            if (!type && data) {
                const rows = _.get(data, "rows", data);
                const total = _.get(data, "total", _.get(data, "length", 0))
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total
                    }
                })
            }
        });
    };

    loadCboTransaction = async () => {
        const res = await Api.put("/w75f8000/load-transaction", this.params);
        const resData = _.get(res, "data", []);
        const resDataErrMessage = _.get(resData, "Message", "");
        const resErrMessage = _.get(res, "message", "");
        if (resErrMessage || resDataErrMessage) {
            Config.popup.show("ERROR", resErrMessage);
            return false;
        }
        this.setState({
            dataCboTransaction: resData
        });
    }

    onCloseModalByFormID = () => {
        this.setState({
            showPopupByFormID: false
        })
    }

    onChangeTabGroup = (_, value) => {
        const {StatusID} = this.state;
        this.resetParamOnChangingTab();
        this.setState({GroupID: value}, () => {
            this.loadGrid();
            if (StatusID !== 0) this.setState({totalDataOT1stStatusTab: "..."})
        });

    };

    onChangeTabStatus = (_, value) => {
        this.resetParamOnChangingTab();
        this.setState({StatusID: value}, () => {
            this.loadGrid();
        });

    };

    onChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        this.loadGrid(true);
    };

    onChangePerPage = (perPage) => {
        this.filter.skip = 0;
        this.filter.limit = perPage;
        this.loadGrid(true);

    };

    onChangePageEmployeeGrid = (paramFilter) => {
        this.filterEmployees[1] = {...this.filterEmployees[1], ...paramFilter}
        this.loadCboEmployees(_, 1);
    }

    onSearchEmployeeGrid = (strSearch) => {
        this.filterEmployees[1].search = strSearch;
        this.loadCboEmployees(_, 1)
    }

    onFilter = () => {
        this.filter.skip = 0;
        this.filter.limit = 10;
        this.loadCboTransaction();
        this.loadCboEmployees(true)
    }

    onFilterChange = (key, e) => {
        const value = _.get(e, "value", _.get(e, "target.value", ""));
        const stateProp = ["DateFrom", "DateTo", "ObjectName"];
        if (_.includes(stateProp, key)) {
            this.setState({
                [key]: value
            });
            return true;
        }
        this.filter[key] = value;
    }

    renderFilter = () => {
        const {TransactionID, Employee} = this.filter;
        const {
            isGridLoading,
            dataCboTransaction,
            dataCboEmployees,
            isEmployeeLoading,
            isCboTransactionLoading,
            DateFrom,
            DateTo,
            ObjectName
        } = this.state;
        return (
            <Filter
                disabled={isGridLoading}
                onOpened={this.onFilter}
                placeholder={Config.lang("Nhap_tu_tim_kiem")}
                openOnFieldClick={true}
                renderFilter={
                    () => {
                        return (
                            <>
                                <FormGroup style={{marginBottom: "7px"}}>
                                    <Row>
                                        <Col xs={12} sm={2}>
                                            <label style={{lineHeight: "49px"}}
                                                   className={"MuiFormLabel-root"}>{Config.lang("Ngay_tao")}</label>
                                        </Col>
                                        <Col xs={12} sm={5}>
                                            <DateBoxPicker
                                                max={DateTo}
                                                style={{marginLeft: "5px"}}
                                                onValueChanged={(e) => this.onFilterChange("DateFrom", e)}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                placeholder={"DD/MM/YYYY"}
                                                value={DateFrom}
                                            />
                                        </Col>
                                        <Col xs={12} sm={5}>
                                            <DateBoxPicker
                                                min={DateFrom}
                                                style={{marginLeft: "5px"}}
                                                onValueChanged={(e) => this.onFilterChange("DateTo", e)}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                placeholder={"DD/MM/YYYY"}
                                                value={DateTo}
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup style={{marginBottom: "7px"}}>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <Combo
                                                label={Config.lang("Quy_trinh")}
                                                dataSource={dataCboTransaction}
                                                displayExpr={"TransactionName"}
                                                valueExpr={"TransactionID"}
                                                onValueChanged={(e) => this.onFilterChange("TransactionID", e)}
                                                showClearButton={true}
                                                value={TransactionID}
                                                loading={isCboTransactionLoading}
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup style={{marginBottom: "7px"}}>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <Combo.LoadMore
                                                dataSource={dataCboEmployees.rows}
                                                skip={this.filterEmployees[0].skip}
                                                limit={this.filterEmployees[0].limit}
                                                displayExpr={"EmployeeName"}
                                                keyExpr={'EmployeeID'}
                                                valueExpr={'EmployeeID'}
                                                value={Employee}
                                                stylingMode={'outlined'}
                                                loading={isEmployeeLoading}
                                                label={Config.lang('Nguoi_tao')}
                                                itemRender={(e) => {
                                                    const {data} = e;
                                                    if (!data) return null;
                                                    return data.EmployeeID + ' - ' + data.EmployeeName;
                                                }}
                                                showClearButton={true}
                                                onValueChanged={e => this.onFilterChange("Employee", e)}
                                                onInputChanged={(e) => {
                                                    this.filterEmployees[0].search = e.target.value;
                                                    this.filterEmployees[0].skip = 0;
                                                    this.loadCboEmployees(true);
                                                }}
                                                onLoadMore={(e) => {
                                                    this.filterEmployees[0].skip = e.skip;
                                                    this.filterEmployees[0].limit = e.limit;
                                                    this.loadCboEmployees();
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup style={{marginBottom: "7px"}}>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <TextField
                                                fullWidth
                                                value={ObjectName}
                                                onChange={(e) => this.onFilterChange("ObjectName", e)}
                                                label={Config.lang("Doi_tuong")}
                                                variant="outlined"/>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup className={"display_row align-center valign-middle"}>
                                    <ButtonGeneral
                                        name={Config.lang("Tim_kiem")}
                                        typeButton={"search"}
                                        size={"large"}
                                        color={"primary"}
                                        variant={"outlined"}
                                        onClick={() => this.loadGrid()}
                                    />
                                </FormGroup>
                            </>
                        )
                    }
                }
            />
        )
    };

    renderEmpAvatar = (e) => {
        const {data} = e.row;
        return (
            <div style={{display: "flex", alignItems: "center"}}>
                <UserImage valueExpr={'UserID'} keyExpr={'CreateUserID'} width={38} height={38} data={data}/>
            </div>
        );
    };

    renderApprovers = (e) => {
        const {classes} = this.props;
        const {data} = e.row;
        const dataApprover = _.get(data, "ApproverName.dataApprover", [])
        return (
            <div className={classes.approverCol}>
                <span>{_.map(dataApprover, (approverName, i) => <React.Fragment
                    key={i}>{approverName}<br/></React.Fragment>)}</span>
            </div>
        )
    };

    onOpenPopup = (state, option = {}, cb = () => {
    }) => {
        this.setState({
            [state]: true,
            ...option,
        }, cb)
    }

    onClosePopup = (state, isReload = false) => {
        if (isReload) {
            this.setState({
                selectedRowKeys: [],
                selectedRowsData: []
            }, () => {
                this.loadGrid();
                this.preSelectedRowKeys.current = [];
                this.preDataRowsSelected.current = []
            })
        }
        this.setState({
            [state]: false
        })
    }

    onSelectionChanged = async (e) => {
        const {dataGrid} = this.state;
        const {selectedRowKeys, selectedRowsData} = e;
        let allSelectedRowKeys = _.uniq([...(this.preSelectedRowKeys.current ?? []), ...selectedRowKeys]);
        let allSelectedDataRows = _.uniqBy([...(this.preDataRowsSelected.current ?? []), ...selectedRowsData], "TransID");
        const currentRowSelected = this.getCurrentRowsSelected("key", dataGrid.rows, allSelectedRowKeys);
        const deselectedRowKeys = _.filter(currentRowSelected, rowKey => !_.includes(selectedRowKeys, rowKey));
        await (() => {
            this.preSelectedRowKeys.current = _.filter(allSelectedRowKeys, rowKey => !_.includes(deselectedRowKeys, rowKey))
            this.preDataRowsSelected.current = _.filter(allSelectedDataRows, item => !_.includes(deselectedRowKeys, item.TransID));
        })();
        this.setState({selectedRowKeys, selectedRowsData});
    }

    renderAction = (e) => {
        const {data} = e.row;
        return (<IconButton
            onClick={() => this.onOpenPopup("showW75F8002", {transIDSelected: _.get(data, "TransID", "showW75F8002")})}>
            <Icon className={"far fa-clock"}/>
        </IconButton>)
    }

    render() {

        const {classes} = this.props;
        const {
            titleModal,
            module: Component,
            FormID,
            keyProps,
            TransID,
            GroupID,
            StatusID,
            dataGrid,
            showW75F8002,
            showW75F8001,
            selectedRowKeys,
            isGridLoading,
            transIDSelected,
            isEmployeeLoading,
            showPopupByFormID,
            totalDataOT1stStatusTab,
        } = this.state;
        const {skip, limit} = this.filter;
        const allSelectedRowKeys = _.uniq([...(this.preSelectedRowKeys.current ?? []), ...this.state.selectedRowKeys]);
        const allSelectedDataRows = _.uniqBy([...(this.preDataRowsSelected.current ?? []), ...this.state.selectedRowsData], "TransID");
        const countNumberAllSelectedRowKeys = _.size(allSelectedRowKeys);
        const hasAVoucherChecked = StatusID === 0 && countNumberAllSelectedRowKeys > 0;
        return (
            <>
                <Modal open={showPopupByFormID} maxWidth={"md"} fullWidth={true}
                       title={titleModal}
                       onClose={() => this.onCloseModalByFormID(false)}>
                    {Component && <Modal.Content>
                        <Component
                            open={showPopupByFormID}
                            mode={"view"}
                            FormID={FormID ?? ''}
                            data={{[keyProps]: TransID}}
                            onClose={() => this.onCloseModalByFormID(false)}
                        />
                    </Modal.Content>}
                </Modal>
                {showW75F8001 ? <W75F8001
                        loading={isEmployeeLoading}
                        skip={this.filterEmployees[1].skip}
                        limit={this.filterEmployees[1].limit}
                        search={this.filterEmployees[1].search}
                        onSearchEmployee={this.onSearchEmployeeGridPopup}
                        onChangePage={this.onChangePageEmployeeGrid}
                        open={showW75F8001}
                        onClose={this.onClosePopup}
                        voucherSelected={allSelectedDataRows}/>
                    : <W75F8002 transID={transIDSelected} onClose={this.onClosePopup} open={showW75F8002}/>}
                <div className={"hidden"}>{this.renderFilter()}</div>
                <ActionToolbar title={Config.lang("Truy_van_don_tu")}>
                    <ButtonGeneral
                        name={`${Config.lang('Thay_the_nguoi_duyet')} ${!!countNumberAllSelectedRowKeys ? countNumberAllSelectedRowKeys : ""}`}
                        typeButton={'add'}
                        style={{textTransform: 'uppercase'}}
                        size={"large"}
                        onClick={() => this.onOpenPopup('showW75F8001', {}, () => {
                            this.loadCboEmployees(_, 1);
                        })}
                        disabled={isGridLoading || !hasAVoucherChecked}
                    />
                </ActionToolbar>
                <div className={classes.contentContainer}>
                    <div style={{display: "flex"}}>
                        <div>
                            <Tabs
                                className={classes.tabs}
                                indicatorColor={"primary"}
                                orientation="vertical"
                                value={GroupID}
                                onChange={this.onChangeTabGroup}
                            >
                                <Tab label={Config.lang("Tat_ca_don_tu")} disabled={isGridLoading}/>
                                <Tab label={Config.lang("Don_tu_cua_toi")} disabled={isGridLoading}/>
                                <Tab label={Config.lang("Don_tu_toi_duyet")} disabled={isGridLoading}/>
                            </Tabs>
                        </div>
                        <div className={classes.tabGridRight}>
                            <Tabs
                                // classes={{indicator}}
                                indicatorColor="primary"
                                textColor="primary"
                                value={StatusID}
                                onChange={this.onChangeTabStatus}
                            >
                                <Tab label={Config.lang("Cho_duyet") + `(${totalDataOT1stStatusTab})`}
                                     disabled={isGridLoading}/>
                                <Tab label={Config.lang("Da_duyet")} disabled={isGridLoading}/>
                                <Tab label={Config.lang("Tu_choi")} disabled={isGridLoading}/>
                            </Tabs>
                            <GridContainer
                                hoverStateEnabled={true}
                                style={{border: 'none'}}
                                dataSource={dataGrid.rows}
                                showRowLines={true}
                                keyExpr={'TransID'}
                                showBorders={false}
                                showColumnLines={false}
                                noDataText={Config.lang("No_data")}
                                loading={isGridLoading}
                                height={850}
                                onDbCellClick={this.onDbCellClick}
                                totalItems={dataGrid.total}
                                itemPerPage={limit}
                                skipPerPage={skip}
                                typePaging={"remote"}
                                onChangePage={this.onChangePage}
                                onChangePerPage={this.onChangePerPage}
                                selectedRowKey={selectedRowKeys}
                                selection={{
                                    deferred: false,
                                    allowSelectAll: true,
                                    selectAllMode: "allPages",
                                    mode: StatusID === 0 ? "multiple" : "single",
                                    showCheckBoxesMode: "always"
                                }}
                                onSelectionChanged={this.onSelectionChanged}
                            >
                                <Column alignment={"left"}
                                        caption={Config.lang("Nguoi_tao")}
                                        cellRender={this.renderEmpAvatar}/>
                                <Column alignment={"left"} caption={Config.lang("Ma_doi_tuong")}
                                        dataField={"ObjectID"}/>
                                <Column alignment={"left"} caption={Config.lang("Ten_doi_tuong")}
                                        dataField={"ObjectName"}/>
                                <Column alignment={"left"} caption={Config.lang("Nguoi_duyet")}
                                        cellRender={this.renderApprovers}/>
                                <Column alignment={"center"} caption={Config.lang("Trang_thai")}
                                        dataField={"AppStatusName"}/>
                                <Column alignment={"left"} caption={Config.lang("Phong_ban")}
                                        dataField={"DepartmentName"}/>
                                <Column alignment={"left"} caption={Config.lang("Loai_don")}
                                        dataField={"TransactionName"}/>
                                <Column alignment={"left"} caption={Config.lang("Vi_tri")}
                                        dataField={"DutyName"}/>
                                <Column alignment={"left"} caption={Config.lang("Du_an")}
                                        dataField={"ProjectName"}/>
                                <Column alignment={"center"} caption={Config.lang("Ngay_tao")}
                                        dataField={"CreateDate"}/>
                                <Column
                                    alignment={"center"}
                                    cellRender={this.renderAction}/>
                            </GridContainer>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    componentDidMount = () => {
        this.loadGrid();
    }
}

export default compose(connect((state) => (
    {
        getCboEmployees: state.general.getCboEmployees,
    }), (dispatch) => (
    {
        generalActions: bindActionCreators(generalActions, dispatch),
    }
)), withStyles(styles, {withTheme: true}))(W75F8000)
